<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div class="d-flex justify-content-between align-items-center px-1 py-1">
            <div>
              <h5 class="m-0 font-weight-bolder">
                {{ $t("ContactPerson") }}
              </h5>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-right">
                <b-link :to="createContactPersonLink">
                  <b-button variant="primary" class="mr-1 text-nowrap">
                    {{ $t("AnyadirNuevoUsuario") }}
                  </b-button>
                </b-link>
              </div>
              <span class="ml-1 cursor-pointer" @click="visibleFilter = !visibleFilter">
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </div>
          </div>

          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="">
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group label-for="filter-search">
                      <div class="d-flex gap-1">
                        <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text"
                          class="d-inline-block" />
                        <b-button variant="primary" @click="handleSearch">
                          {{ $t("Buscar") }}
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>

          <b-table v-if="items" hover responsive :items="items" :fields="seleccionarcolumnas">
            <template #cell(fullName)="data">
              <div class="">
                <span class="">{{ data.item.name }}</span>
              </div>
            </template>
            <template #cell(clients)="data">
              <span v-for="(c, index) in data.item.clients" :key="`${c.id}-${data.item.id}`">
                <span v-if="index !== 0">, </span>{{ c.name }}
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link :to="{
                  name: 'viewContactPerson',
                  params: { id: data.item.id },
                }" class="mr-1">
                  <feather-icon icon="EyeIcon" size="16" />
                </b-link>
                <b-link :to="{
                  name: 'editContactPerson',
                  params: { id: data.item.id },
                }" class="mr-1">
                  <feather-icon icon="Edit2Icon" size="16" />
                </b-link>
                <span class="text-danger cursor-pointer" @click="deleteUser(data.item.id, data.item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BAvatar,
  BCol,
  BRow,
  BLink,
  BFormGroup,
  BCollapse,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
// import vSelect from "vue-select";
// import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BCollapse,
    BButton,
    BAvatar,
  },
  props: ["subclient"],
  data() {
    return {
      roles: "",
      log: [],
      type: "",
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "25", "50"],
      client: "",
      columns: [
        {
          label: this.$t("Nombre"),
          key: "fullName",
        },
        {
          label: this.$t("Email"),
          key: "email",
        },
        {
          label: this.$t("Cliente"),
          key: "clients",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
          // thStyle: { minWidth: "15rem" },
        },
      ],
      columnsClient: [
        {
          label: this.$t("Nombre"),
          key: "fullName",
        },
        {
          label: this.$t("Email"),
          key: "email",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
          // thStyle: { minWidth: "15rem" },
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
      items: "users/getItems",
      totalItems: "users/getTotalItems",
      listClients: "clients/getItems",
      // currentUser: "auth/getUser",
      role: "auth/getRole",
      selectRoles: "users/getRoles",
    }),
    createContactPersonLink() {
      const subclientId = this.subclient; // Aquí defines el ID del subcliente
      return {
        name: "createContactPerson",
        params: {
          subclient_id: subclientId,
        },
      };
    },
    seleccionarcolumnas() {
      if (this.currentClient) {
        return this.columnsClient;
      }
      return this.columns;
    },
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
        pending: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusVariantText() {
      const statusText = {
        1: "ActivoUser",
        0: "Desactivado",
        pending: "Pendiente",
      };

      return (status) => statusText[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    client() {
      this.chargeData();
    },
    roles() {
      this.chargeData();
    },
  },
  beforeCreate() { },
  created() {
    this.getRoles();
    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: "",
    });
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "users/getListUsers",
      delete: "users/delete",
      getListClients: "clients/getListClients",
      getRoles: "users/getRoles",
    }),
    chargeData() {
      let cliente = this.currentClient ? this.currentClient.id : "";
      if (this.role !== "admin_cliente" && cliente === "") {
        cliente = this.client ? this.client.id : "";
      }
      const rolesSelect = ["client"];

      this.list({
        company: this.currentCompany.id,
        client: cliente,
        subclient: this.subclient,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        roles: rolesSelect,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteUser(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
